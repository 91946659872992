import {authStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async () => {
    const app = useNuxtApp()
    const store = await authStore(app.$pinia)

    try {
        if (
            store.user &&
            store.user.name &&
            store.user.name.length
        ) {
            return navigateTo('/create/custom')
        }
    } catch (e) {
        console.error(e)
    }
})